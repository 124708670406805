@value colors: 'assets/css/colors.module.css';
@value brand from colors;
@value errorRed from colors;
@value smoke from colors;

.text-field {
  line-height: 34px;
  border: 1px solid rgba(20, 42, 57, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding-left: 6px;
  padding-right: 20px;
  outline-color: smoke;
  font-family: "DM Sans", sans-serif;
}
.invalid .text-field {
  border-color: errorRed;
  background-image: url("assets/img/error.svg");
  background-repeat: no-repeat;
  background-position: right 5px center;
  outline-color: errorRed;
}
.field-label {
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: #142a39;
}
.asterikStyle {
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.field-label-checkbox {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #142a39;
  margin-left: 5px;
}
.checkbox-field {
  width: 15px;
  height: 15px;
}
/* .checkbox-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
} */
.email-field-label {
  composes: field-label;
  font-family: "DM Sans", sans-serif;
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.remove {
  color: brand;
  /* position: absolute; */
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* top: 1px;
  right: 0px; */
  text-transform: capitalize;
  padding-right: 0;
}
.error {
  color: errorRed;
  display: none;
  font-size: 14px;
  text-transform: none;
  font-family: "DM Sans", sans-serif;
}
.invalid .error {
  display: block;
}
.select-field {
  composes: text-field;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 38px;
  font-family: "DM Sans", sans-serif;
  background: url(../../assets/img/down_arrow1.png) no-repeat right #fff;
  background-position: right 10px top 10px;
  outline-color: smoke;
}
select::-ms-expand {
  display: none;
}
.invalid .select-field {
  background-position: right 10px center;
}
.emailSubText {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #182c3d;
  margin: 8px auto;
  text-transform: none !important;
}
@media screen and (max-width: 400px) {
  .email-field-label {
    display: inline;
  }
  .remove {
    float: right;
  }
}
