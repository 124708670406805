@value colors: 'assets/css/colors.module.css';
@value brand from colors;
@value breakpoints: 'assets/css/breakpoints.module.css';
@value sm from breakpoints;

.brand-color {
  color: brand;
}
.confirmation {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.sub-headline {
  font-weight: 700;
  margin-top: 20px;
}
.completion-message {
  text-align: center;
  padding-right: 60px;
}
.invite-dependent-button-section{
  text-align: center;
  margin-top: 15px;
}
.success-message {
  font-size: 17px;
  display: block;
  margin: auto;
  max-width: 514px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
}
.add-dependent-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.another-dependent-container {
  display: inline-block;
  margin-left: 20px;
}
.another-dependent-button {
  /* margin-left: 20px; */
  
}
.roundCircle{
  height:10px;
  min-width:10px;
  border-radius: 50%;
  background-color: #6387C5;
  margin-right: 10px;
}
.code-and-steps {
  padding-left: 60px;
  display: flex;
  flex-wrap: wrap;
}
.textCircleDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:2rem auto;
  font-family: 'DM Sans', sans-serif;
}
.your-code {
  composes:  brand-color;
  margin-top: 125px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.next-steps {
  composes:  brand-color;
  margin-top: 100px;
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 36px;
}
.step1 {
  margin-top: 20px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #142A39
}
.step2 {
  margin-top: 24px;
}


.enrollment_header {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #142A39;
}
@media only screen and (max-width: sm) {
  .completion-message {
    padding: 0 20px;
  }
}