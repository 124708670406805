@value colors: 'assets/css/colors.module.css';
@value errorRed from colors;
@value textColor from colors;
@value breakpoints:  'assets/css/breakpoints.module.css';
@value sm from breakpoints;
@value md from breakpoints;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.titles {
  text-align: center;
}
.subheadline {
 font-weight: 600;
 font-size: 19px;
 font-family: 'DM Sans', sans-serif;
}
.enrollLink{
  font-weight: 700;
  margin-bottom: 4vh;
  font-family: 'DM Sans', sans-serif;
  font-size: 19px;
}
.aLink{
  color: #174E8C;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
}
.titleEnrollText{
  margin: 4.5vh auto;
}
.cvsTitleEnrolltext{
  margin-bottom: 5vh;
  font-family: 'DM Sans', sans-serif;
}
.registration-form {
  display: block;
  margin: 0 50px;
}
.form-fields {
  margin-top: 30px;
}
.stepperStyle{
  width: 40%;
  margin: auto;
}
.registration-headline {
  margin-bottom: 12px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-size: 26px;
}
.registration-error-display {
  background: #F9E8E8;
  border: 2px solid errorRed;
  border-radius: 8px;
  padding: 16px 16px 16px 68px;
  background-image: url('assets/img/error.svg');
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 35px 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: none;
}
.registration-error-display .error-triangle {
  width: 35px;
  height: 30px;
}
.registration-display-errors {
  display: block;
}
.registration-subtitle {
  font-size: 17px;
  font-weight: 400;
  color: #142A39;
  font-family: 'DM Sans', sans-serif;
}
.all-fields-required {
  color: errorRed;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  font-family: 'DM Sans', sans-serif;
}
.asterikStyle{
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.eligibility-intro {
  font-size: 16px;
}
.eligibility-headline {
  margin-top: 22px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #142A39;
}
.errorLine{
  font-size: 13px;
  color: rgb(204, 61, 63);
  font-weight: 100;
  font-family: 'DM Sans', sans-serif;
}
.eligibility-label {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #142A39;
}
.form-row {
  height: 100px;
}
.titleCheckBox{
  font-size: 15px;
  font-weight: bold;
  height: 40px;
}
/* .atrialHypertensionStyle{
  margin-left: 50px;
} */
.form-consent {
  padding-top: 0px !important;
}
.add-dependent-container {
  margin-bottom: 30px;
}
.submit-button-container {
  margin-top: 20px;
}

.backdrop {
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 20px;
  top: -20px;
  bottom: -20px;
}
.backdrop-container {
  position: relative;
}
.invite-outline-button {
  text-decoration:none;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #142A39;
  border-color: #142A39;
  font-family: 'DM Sans', sans-serif;
}
 .atrialHypertensionStyle{
  margin-left: 35px;
}
.gridContainer{
  color:#182C3D !important;
  font-family: 'DM Sans', sans-serif;
}
@media only screen and (max-width: md) {
  .backdrop-container {
    margin-top: 40px;
  }
  .eligibility {
    margin: 0 50px;
  }
  .add-dependent-container {
    margin-top: 30px;
  }
  .stepperStyle{
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .stepperStyle{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .button_alignment{
    flex-direction: column;
  }
}